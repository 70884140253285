import * as React from "react";
import { Component } from "react";
import {default as classNames} from "classnames";

export interface Props {
    passwordIsCorrect: any
}

interface State {
    inputValue: string,
    shakeModal: boolean,
}

class InsecurePasswordModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            inputValue: '',
            shakeModal: false
        }
    };

    render() {
        return (
            <div className={'password'}>
                <div className={'password-overlay'}>


                </div>
                <div className={classNames({
                    'password-modal': true,
                    '--shake': this.state.shakeModal,
                })}>
                    WACHTWOORD<br />

                    <input className={'password-modal__input'} type={'password'} value={this.state.inputValue}
                           onChange={(evt) => this.updateInputValue(evt)}
                           onKeyPress={(evt) => this.handleKeyPress(evt)}></input><br />

                    <div className={'password-modal__button'} onClick={() => this.handlePasswordSubmit()}>
                        Verder &gt;
                    </div>
                </div>
            </div>
        );
    }

    private handlePasswordSubmit() {
        if (this.state.inputValue === 'mboles' || process.env.NODE_ENV === 'development') {
            this.props.passwordIsCorrect()
        } else {
            this.setState({
                inputValue: '',
                shakeModal: true,
            });

            setTimeout(() => this.setState({
                shakeModal: false,
            }), 500);
        }
    }

    private updateInputValue(evt: any) {
        this.setState({
            inputValue: evt.target.value
        });
    }

    private handleKeyPress(evt: any) {
        if (evt.key == 'Enter') {
            this.handlePasswordSubmit();
        }
    }
}

export default InsecurePasswordModal;
