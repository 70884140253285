import * as React from "react";
import { Component } from "react";
import SingleSelect from "./items/SingleSelect";
import Text from "./items/Text";
import NoItemTemplate from "./items/NoItemTemplate";
import Home from "./Home";
import Top from "./Top";
import End from "./items/End";
import Video from "./items/Video";
import { getBackgroundImage, getHomePattern, getBackgroundColor } from "../util/imageHelper";
import Hotspot from "./items/Hotspot";
import MultipleSelect from "./items/MultipleSelect";
import Quotes from "./items/Quotes";
import InsecurePasswordModal from "./InsecurePasswordModal";
import Certificate from "./items/Certificate";

export interface Props {
    lessons: {
        title: string, chapters: {
            title: string, items: {
                type: string,
                _source: string,
                _error?: string,
                bg?: string,
                heading?: string
                sub?: string
                body?: string
                bodyLeft?: string
                bodyRight?: string
                items?: []
                options?: [];
                feedback?: string;
                videoId?: number;
                klikplaattype?: string;
                select?: string; // for klikplaattype
                evaluationLink?: string // evaluation link used on eind_scherm
            }[]
        }[]
    }[];
}

interface State {
    isAtHomePage: boolean,
    isPasswordCorrect: boolean,
    lessonIndex: number,
    chapterIndex: number,
    itemIndex: number,
    itemNumber: number,
    itemsChosenOptionsPerLesson: any,
}

class Main extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let itemsChosenOptionsPerLesson = [];

        for (let i = 0; i < this.props.lessons.length; i++) {
            itemsChosenOptionsPerLesson[i] = [];
        }

        this.state = {
            isAtHomePage: true,
            isPasswordCorrect: false,
            lessonIndex: 0,
            chapterIndex: 0,
            itemIndex: 0,

            itemNumber: 0,
            itemsChosenOptionsPerLesson: itemsChosenOptionsPerLesson,
        }
    };

    render() {
        let mainStyle = {};
        if (this.getCurrentItem().bg !== undefined && getBackgroundImage(this.getCurrentItem().bg) !== undefined) {
            mainStyle = { backgroundImage: `url(${getBackgroundImage(this.getCurrentItem().bg)})` }
        }

        let patternBgStyle = {};
        if (this.state.isAtHomePage) {
            patternBgStyle = { backgroundImage: `url(${getHomePattern()})` }
        } else {
            patternBgStyle = { backgroundColor: getBackgroundColor(this.state.itemNumber, this.getCurrentItem().bg)}
        }

        return (
            <div className={'pattern-bg'}
                 style={patternBgStyle}>
                {
                    this.state.isAtHomePage &&
                    <Home goToLesson={(lesson: number) => this.goToLesson(lesson)} />
                }

                {
                    !this.state.isPasswordCorrect && this.state.lessonIndex === 4 &&
                    <InsecurePasswordModal passwordIsCorrect={() => this.setState({isPasswordCorrect: true})} />
                }

                {
                    !this.state.isAtHomePage &&
                    <div className={'main'} style={mainStyle}>
                        <Top lessonIndex={this.state.lessonIndex}
                             chapterIndex={this.state.chapterIndex}
                             goToHomePage={() => this.goToHomePage()}
                             itemNumber={this.state.itemNumber}
                             lessons={this.props.lessons}
                        />

                        {this.renderItemTemplate()}
                    </div>
                }

                {
                    process.env.NODE_ENV === 'development' &&
                        <div>
                    <div className={'debug-next'} onClick={()  => this.goToNextItem()}>debug next</div>
                    <div className={'debug-end'} onClick={()  => this.goToLastItem()}>debug end</div>
                        </div>
                }

            </div>
        );
    }

    private goToLesson(lesson: number) {
        this.setState((state, props) => ({
            isAtHomePage: false,
            lessonIndex: lesson,
            chapterIndex: 0,
            itemIndex: 0,
            itemNumber: 0,
        }))
    }

    private goToHomePage() {
        this.setState({
            isAtHomePage: true,
        })
    }

    private storeItemChosenOptions(options: any) {
        let itemsChosenOptionsPerLesson = this.state.itemsChosenOptionsPerLesson;
        itemsChosenOptionsPerLesson[this.state.lessonIndex][this.state.itemNumber] = options;

        this.setState((state, props) => ({
            itemsChosenOptionsPerLesson: itemsChosenOptionsPerLesson
        }));
    }

    private goToNextItem() {
        if (this.state.itemIndex === this.props.lessons[this.state.lessonIndex].chapters[this.state.chapterIndex].items.length - 1) {
            this.setState((state, props) => ({
                chapterIndex: state.chapterIndex + 1,
                itemIndex: 0,
                itemNumber: state.itemNumber + 1,
            }));
        } else {
            this.setState((state, props) => ({
                itemIndex: state.itemIndex + 1,
                itemNumber: state.itemNumber + 1,
            }));
        }
    }

    private goToLastItem() {
        this.setState((state, props) => {
            const lastChapterIndex = props.lessons[this.state.lessonIndex].chapters.length - 1;
            const lastItemIndex = props.lessons[this.state.lessonIndex].chapters[lastChapterIndex].items.length - 1;
            return {
            chapterIndex: lastChapterIndex,
            itemIndex: 0,
            itemNumber: lastItemIndex,
            }
        });
    }

    private goToPreviousItem() {
        if(this.state.chapterIndex === 0 && this.state.itemIndex === 0) {
            this.goToHomePage();
        } else if (this.state.itemIndex === 0) {
            this.setState((state, props) => ({
                chapterIndex: state.chapterIndex - 1,
                itemIndex: props.lessons[state.lessonIndex].chapters[state.chapterIndex - 1].items.length - 1,
                itemNumber: state.itemNumber - 1,
            }));
        } else {
            this.setState((state, props) => ({
                itemIndex: state.itemIndex - 1,
                itemNumber: state.itemNumber - 1,
            }));
        }
    }

    private renderItemTemplate() {
        switch (this.getCurrentItem().type) {
            case 'tekst_kop_body':
            case 'tekst_kop_body_2_vakken':
            case 'tekst_kop_body_tips':
            case 'tekst_kop_body_opsomming':
                return <Text type={this.getCurrentItem().type}
                             bg={this.getCurrentItem().bg}
                             heading={this.getCurrentItem().heading}
                             sub={this.getCurrentItem().sub}
                             body={this.getCurrentItem().body}
                             bodyLeft={this.getCurrentItem().bodyLeft}
                             bodyRight={this.getCurrentItem().bodyRight}
                             items={this.getCurrentItem().items}
                             goToNextItem={() => this.goToNextItem()}
                             goToPreviousItem={() => this.goToPreviousItem()}
                />;
            case 'vraag_1_goed':
            case 'stelling_waar_onwaar':
            case 'vraag_alle_goed':
                return <SingleSelect type={this.getCurrentItem().type}
                                     bg={this.getCurrentItem().bg}
                                     heading={this.getCurrentItem().heading}
                                     sub={this.getCurrentItem().sub}
                                     options={this.getCurrentItem().options}
                                     feedback={this.getCurrentItem().feedback}
                                     goToNextItem={() => this.goToNextItem()}
                                     goToPreviousItem={() => this.goToPreviousItem()}
                                     storeChosenOptions={(option: any) => this.storeItemChosenOptions(option)}
                                     chosenOption={this.getCurrentItemsChosenOptions()}
                 />;
            case 'vraag_multiple_select':
            case 'vraag_multiple_select_alle_goed':
                return <MultipleSelect bg={this.getCurrentItem().bg}
                                       heading={this.getCurrentItem().heading}
                                       sub={this.getCurrentItem().sub}
                                       options={this.getCurrentItem().options}
                                       feedback={this.getCurrentItem().feedback}
                                       goToNextItem={() => this.goToNextItem()}
                                       goToPreviousItem={() => this.goToPreviousItem()}
                                       storeChosenOptions={(options: any) => this.storeItemChosenOptions(options)}
                                       chosenOptions={this.getCurrentItemsChosenOptions()}
                />;
            case 'eind_scherm':
                return <End bg={this.getCurrentItem().bg}
                            heading={this.getCurrentItem().heading}
                            lessonIndex={this.state.lessonIndex}
                            sub={this.getCurrentItem().sub}
                            evaluationLink={this.getCurrentItem().evaluationLink}
                            goToHome={() => this.goToHomePage()}
                            goToPreviousItem={() => this.goToPreviousItem()}
                />;
            case 'certificaat':
                return <Certificate bg={this.getCurrentItem().bg}
                            heading={this.getCurrentItem().heading}
                            lessonIndex={this.state.lessonIndex}
                            sub={this.getCurrentItem().sub}
                            goToNextItem={() => this.goToNextItem()}
                            goToPreviousItem={() => this.goToPreviousItem()}
                />;
            case 'video':
                return <Video bg={this.getCurrentItem().bg}
                              heading={this.getCurrentItem().heading}
                              videoId={this.getCurrentItem().videoId}
                              goToNextItem={() => this.goToNextItem()}
                              goToPreviousItem={() => this.goToPreviousItem()}
                              storeIsCompleted={(options: any) => this.storeItemChosenOptions(options)}
                              isCompleted={this.getCurrentItemsChosenOptions()}
                />;
            case 'klikplaat':
                return <Hotspot bg={this.getCurrentItem().bg}
                                type={this.getCurrentItem().klikplaattype}
                                heading={this.getCurrentItem().heading}
                                sub={this.getCurrentItem().sub}
                                select={this.getCurrentItem().select}
                                goToNextItem={() => this.goToNextItem()}
                                goToPreviousItem={() => this.goToPreviousItem()}
                />;
            case 'quotes':
                return <Quotes bg={this.getCurrentItem().bg}
                               heading={this.getCurrentItem().heading}
                               sub={this.getCurrentItem().sub}
                               items={this.getCurrentItem().items}
                               goToNextItem={() => this.goToNextItem()}
                               goToPreviousItem={() => this.goToPreviousItem()}
                />;
            default:
                return <NoItemTemplate item={this.getCurrentItem()}
                                       goToNextItem={() => this.goToNextItem()}
                                       goToPreviousItem={() => this.goToPreviousItem()}
                />
        }
    }

    private getCurrentItem() {
        return this.props.lessons[this.state.lessonIndex].chapters[this.state.chapterIndex].items[this.state.itemIndex];
    }

    private getCurrentItemsChosenOptions() {
        if (this.state.itemsChosenOptionsPerLesson[this.state.lessonIndex] === undefined) {
            return undefined;
        } else {
            return this.state.itemsChosenOptionsPerLesson[this.state.lessonIndex][this.state.itemNumber]
        }
    }
}

export default Main;
