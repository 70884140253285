import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";

export interface Props {
    bg: string,
    heading: string,
    lessonIndex: number,
    sub?: string,
    goToPreviousItem: any,
    goToHome: any,
    evaluationLink: string|null
}

interface State {
}

class End extends Component<Props, State> {
    render() {
        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'title-container'}>
                        <div className={'title-container__title'}>
                            <h2>{markedText(this.props.heading)}</h2>
                            {
                                this.props.sub &&
                                <div className={'title-container__text'}>
                                    {markedText(this.props.sub)}
                                </div>
                            }
                        </div>
                    </div>

                    <div className={'body-container'}>
                            {
                                this.props.evaluationLink && this.props.evaluationLink !== '' ?
                                    <div className={'text__single-body-questionnaire'}>
                                        <div>
                                            <span className='highlight'>Wat vond jij van deze les?</span><br/>
                                            Laat het ons weten en vul
                                            {' '}
                                            <a href={this.props.evaluationLink} target='_blank'>deze korte vragenlijst</a>
                                            {' '}in.<br />
                                            Iedere 3 maanden wordt er onder de inzendingen <br />
                                            een cadeaubon verloot van €25,-.
                                        </div>
                                    </div>
                                    :
                                    undefined
                            }
                    </div>
                </div>

                <NavBar isLastItem={true}
                        handleNextClick={() => this.props.goToHome()}
                        handlePreviousClick={() => this.props.goToPreviousItem()}
                />
            </div>
        );
    }
}

export default End;
