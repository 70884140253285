import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";

export interface Props {
    type: string,
    bg: string,
    heading: string,
    sub: string,
    body?: string,
    bodyLeft?: string,
    bodyRight?: string,
    items?: [],
    goToNextItem: any,
    goToPreviousItem: any,
}

interface State {
}

class Text extends Component<Props, State> {
    render() {
        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'title-container'}>
                        <div className={'title-container__title'}>
                            <h2>{markedText(this.props.heading)}</h2>
                            {
                                this.props.sub &&
                                <div className={'title-container__text'}>
                                    {markedText(this.props.sub)}
                                </div>
                            }
                        </div>
                    </div>

                    <div className={'body-container'}>
                        {this.getBody()}
                    </div>
                </div>

                <NavBar handleNextClick={() => this.props.goToNextItem()}
                        handlePreviousClick={() => this.props.goToPreviousItem()}
                />
            </div>
        );
    }

    private getBody() {
        if (this.props.body !== undefined) {
            return <div className={'text__single-body'}>{markedText(this.props.body)}</div>
        }

        if (this.props.bodyLeft !== undefined || this.props.bodyRight !== undefined) {
            return <div className={'text__duo-body'}>
                <div className={'text__left-body'}>{markedText(this.props.bodyLeft)}</div>
                <div className={'text__right-body'}>{markedText(this.props.bodyRight)}</div>
            </div>
        }

        if (this.props.items !== undefined) {
            let items = this.props.items.map((item, index) =>
                <li key={index}>
                    {markedText(item)}
                </li>
            );

            return <div className={'text__items-body'}>
                <ul className={this.props.type === 'tekst_kop_body_tips' ? '--stars' : ''}>{items}</ul>
            </div>
        }
    }
}

export default Text;
