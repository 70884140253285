import * as React from "react";
import { Component } from "react";
import { getLessonIconFilled } from "../util/lessonIcon";

export interface Props {
    goToLesson: any
}

interface State {
}

class Home extends Component<Props, State> {
    render() {
        return (
            <div className={'home'}>
                <div className={'home-logo-container'}>
                    <img src={require('../../src/resources/images/logo.svg')}
                         className={'home-logo'}
                         alt={'lang leve de liefde logo'} />
                </div>

                {this.renderLessonBlock(1, getLessonIconFilled(1), 'Liefde, relaties en seks')}
                {this.renderLessonBlock(2, getLessonIconFilled(2), 'Veilig vrijen en soa’s')}
                {this.renderLessonBlock(3, getLessonIconFilled(3), 'Condooms en andere anticonceptie')}
                {this.renderLessonBlock(4, getLessonIconFilled(4), 'Onbedoelde zwangerschap')}
                {this.renderLessonBlock(5, getLessonIconFilled(5), 'Rolpatronen')}
                {/*{this.renderLessonBlock(0, getLessonIconFilled(0), 'Dit is een voorbeeld les')}*/}

                <div className={'home-sound'}>
                    Zet je geluid aan en <br />
                    gebruik je koptelefoon
                </div>
            </div>
        );
    }

    private renderLessonBlock(number: number, icon: string, title: string) {
        return (
            <div className={'home-lesson'} onClick={() => this.props.goToLesson(number)}>
                <div className={'home-lesson__head'}>
                    <img src={icon} alt={''} />
                    Les {number}
                </div>
                <div className={'home-lesson__name'}><div>{title}</div></div>
                <div className={'home-lesson__arrow'}>
                    <img src={require('../../src/resources/icons/arrow_cirkle.svg')} alt={''} />
                </div>
            </div>
        );
    }
}

export default Home;
