import * as React from "react";
import { Component } from "react";
import NavBar from "../NavBar";

export interface Props {
    item: any,
    goToNextItem: any,
    goToPreviousItem: any,
}

interface State {
}

class NoItemTemplate extends Component<Props, State> {
    render() {
        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'unknown-template'}>
                        source: <br /> {this.props.item._source} <br /><br />
                        error: <br /> {this.props.item._error} <br /><br />
                        item: <br /> {JSON.stringify(this.props.item)}
                    </div>

                </div>

                <NavBar handleNextClick={() => this.props.goToNextItem()}
                        handlePreviousClick={() => this.props.goToPreviousItem()}
                />
            </div>
        );
    }
}

export default NoItemTemplate;
