import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";

export interface Props {
    bg: string,
    heading: string,
    lessonIndex: number,
    sub?: string,
    goToPreviousItem: any,
    goToNextItem: any,
}

interface State {
}

class Certificate extends Component<Props, State> {
    render() {
        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'title-container'}>
                        <div className={'title-container__title'}>
                            <h2>{markedText(this.props.heading)}</h2>
                            {
                                this.props.sub &&
                                <div className={'title-container__text'}>
                                    {markedText(this.props.sub)}
                                </div>
                            }
                        </div>
                    </div>

                    <div className={'body-container'}>
                        <div className={'text__single-body'}>
                            {this.getBody()}
                        </div>
                    </div>
                </div>

                <NavBar isLastItem={false}
                        handleNextClick={() => this.props.goToNextItem()}
                        handlePreviousClick={() => this.props.goToPreviousItem()}
                />
            </div>
        );
    }

    private getBody() {
        switch (this.props.lessonIndex) {
            case 1:
                return <React.Fragment>
                    <p>Download <a href={require("../../resources/certificaten/certificaat_les_1_lllmbo.pdf")}
                                   target="_blank" download>hier</a> het certificaat dat je deze les hebt afgerond.</p>

                    <p>Wil je meer weten? Kijk op <a href="https://www.sense.info" target="_blank">www.sense.info</a> voor
                        tips en informatie over liefde, relaties en seks.</p>
                </React.Fragment>;
            case 2:
                return <React.Fragment>
                    <p>Download <a href={require("../../resources/certificaten/certificaat_les_2_lllmbo.pdf")}
                                   target="_blank" download>hier</a> het certificaat dat je deze les hebt afgerond.</p>

                    <p>Wil je meer weten? Kijk op <a href="https://www.sense.info" target="_blank">www.sense.info</a> voor
                        tips en informatie over veilig vrijen en soa.</p>
                </React.Fragment>;
            case 3:
                return <React.Fragment>
                    <p>Download <a href={require("../../resources/certificaten/certificaat_les_3_lllmbo.pdf")}
                                   target="_blank" download>hier</a> het certificaat dat je deze les hebt afgerond.</p>

                    <p>Wil je meer weten? Kijk op <a href="https://www.sense.info" target="_blank">www.sense.info</a> voor
                        tips en informatie over de pil, condooms en andere anticonceptie.</p>
                    <p>Kijk op <a href="https://anticonceptievoorjou.nl/" target="_blank">www.anticonceptievoorjou.nl</a> om te ontdekken welk middel het beste bij jou past.
                    </p>
                </React.Fragment>;
            case 4:
                return <React.Fragment>
                    <p>Download <a href={require("../../resources/certificaten/certificaat_les_4_lllmbo.pdf")}
                                   target="_blank" download>hier</a> het certificaat dat je deze les hebt afgerond.</p>

                    <p>Wil je meer weten? Kijk op <a href="https://www.sense.info" target="_blank">www.sense.info</a> of
                        op <a href="https://fiom.nl" target="_blank">www.fiom.nl</a> voor tips en informatie over
                        onbedoelde zwangerschappen.</p>
                </React.Fragment>;
            default:
                return <React.Fragment>
                    <p>Je bent klaar.</p>
                </React.Fragment>
        }
    }
}

export default Certificate;
