import * as React from "react";
import { Component } from "react";
import {default as classNames} from "classnames";

export interface Props {
    isNextDisabled?: boolean,
    isLastItem?: boolean,
    handleNextClick: any,
    handlePreviousClick: any,
}

interface State {
}

class NavBar extends Component<Props, State> {
    render() {
        return (
            <div className={'nav-bar'}>
                <div className={'nav-bar__button nav-bar__button--prev'}
                     onClick={() => this.props.handlePreviousClick()}>
                    <span className={'nav-bar__arrow'}>&lt;</span> Terug
                </div>
                <div className={classNames({
                    'nav-bar__button': true,
                    'nav-bar__button--next': true,
                    'nav-bar__button--disabled': this.props.isNextDisabled,
                })}
                     onClick={() => this.handleNextClick()}>
                    {this.props.isLastItem ? 'Klaar' : 'Verder'} <span className={'nav-bar__arrow'}>&gt;</span>
                </div>
            </div>
        );
    }

    private handleNextClick() {
        if (this.props.isNextDisabled) {
            //do nothing
        } else {
            this.props.handleNextClick();
        }
    }
}

export default NavBar;
