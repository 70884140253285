import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";
import {default as classNames} from "classnames";

export interface Props {
    type: string,
    bg: string,
    heading: string,
    sub?: string,
    options: {text: string, correct: boolean}[],
    feedback: string,
    goToNextItem: any,
    goToPreviousItem: any,
    storeChosenOptions: any,
    chosenOption: number,
}

interface State {
    answerIndex: number,
    displayCorrectAnswers: boolean,
    displayFeedback: boolean,
}

class SingleSelect extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            answerIndex: props.chosenOption,
            displayCorrectAnswers: props.chosenOption !== undefined,
            displayFeedback: props.chosenOption !== undefined,
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        // We need this reset when a SingleSelect Items is directly after another SingleSelect.
        if (prevProps.heading !== this.props.heading ||
            prevProps.options !== this.props.options) { //todo make this more robust
            this.setState({
                answerIndex: this.props.chosenOption,
                displayCorrectAnswers: this.props.chosenOption !== undefined,
                displayFeedback: this.props.chosenOption !== undefined,
            })
        }
    }

    render() {
        let options = this.props.options.map((option, index) =>
            <div key={index}
                 className={classNames({
                     'option': true,
                     'option--indented': true,
                     'option--true-false-question': this.props.type === 'stelling_waar_onwaar',
                     'option--selected': this.state.answerIndex === index,
                     'option--false': this.state.displayCorrectAnswers && !option.correct,
                     'option--true': this.state.displayCorrectAnswers && option.correct,
                 })}
                 onClick={() => this.giveAnswer(index)}>
                <div className={'option__content'}>
                    <div className={'option__icon-single-select'}></div>
                    <div className={'option__text'}>
                        {markedText(option.text)}
                    </div>
                </div>
            </div>
        );

        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'title-container'}>
                        <div className={'title-container__title'}>
                            <h2>{markedText(this.props.heading)}</h2>
                            {
                                this.props.sub &&
                                <div className={'title-container__text'}>
                                    {markedText(this.props.sub)}
                                </div>
                            }
                        </div>
                    </div>

                    <div className={'body-container'}>
                        <div className={'options-container'}>
                            {options}
                        </div>
                    </div>

                    {
                        this.state.displayFeedback &&
                        <React.Fragment>
                            <div className={'feedback-overlay'}></div>

                            <div className={'feedback-modal-container'}>
                                <div className={'feedback-modal'}>
                                    {markedText(this.props.feedback)}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

                <NavBar
                    isNextDisabled={this.state.answerIndex === undefined}
                    handleNextClick={() => this.goToNextState()}
                    handlePreviousClick={() => this.goToPreviousState()}
                />
            </div>
        );
    }

    private giveAnswer(index: number) {
        this.setState({ answerIndex: index })
    }

    private goToNextState() {
        if (!this.state.displayCorrectAnswers && !this.state.displayFeedback) {
            this.props.storeChosenOptions(this.state.answerIndex);
            this.setState({
                displayCorrectAnswers: true
            });
        } else if (this.state.displayCorrectAnswers && !this.state.displayFeedback) {
            this.setState({ displayFeedback: true })
        } else if (this.state.displayCorrectAnswers && this.state.displayFeedback) {
            this.props.goToNextItem()
        }
    }

    private goToPreviousState() {
        if (this.state.answerIndex === undefined) {
            this.props.goToPreviousItem();
        } else {
            if (this.state.displayFeedback) {
                this.setState({ displayFeedback: false })
            } else {
                this.props.goToPreviousItem();
            }
        }

    }
}

export default SingleSelect;
