import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";
import {default as classNames} from "classnames";

export interface Props {
    bg: string,
    heading: string,
    sub?: string,
    options: { text: string, correct: boolean }[],
    feedback: string,
    goToNextItem: any,
    goToPreviousItem: any,
    storeChosenOptions: any,
    chosenOptions: number[],
}

interface State {
    answersIndexArray: number[],
    displayCorrectAnswers: boolean,
    displayFeedback: boolean,
}

class MultipleSelect extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const emptyNumbersArray: number[] = []; //todo kan dit niet mooier?

        this.state = {
            answersIndexArray: props.chosenOptions || emptyNumbersArray,
            displayCorrectAnswers: props.chosenOptions !== undefined,
            displayFeedback: props.chosenOptions !== undefined,
        }
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        // We need this reset when a SingleSelect Items is directly after another SingleSelect.
        if (prevProps.heading !== this.props.heading ||
            prevProps.options !== this.props.options) { //todo make this more robust
            const emptyNumbersArray: number[] = [];

            this.setState({
                answersIndexArray: this.props.chosenOptions || emptyNumbersArray,
                displayCorrectAnswers: this.props.chosenOptions !== undefined,
                displayFeedback: this.props.chosenOptions !== undefined,
            })
        }
    }

    render() {
        let options = this.props.options.map((option, index) =>
            <div key={index}
                 className={classNames({
                     'option': true,
                     'option--indented': this.props.options.length <= 4,
                     'option--more-then-four': this.props.options.length > 4,
                     'option--selected': this.isAnswerIndexGiven(index),
                     'option--false': this.state.displayCorrectAnswers && !option.correct,
                     'option--true': this.state.displayCorrectAnswers && option.correct,
                 })}
                 onClick={() => this.giveAnswer(index)}>
                <div className={'option__content'}>
                    <div className={'option__icon-multiple-select'}></div>
                    <div className={'option__text'}>
                        {markedText(option.text)}
                    </div>
                </div>
            </div>
        );

        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'title-container'}>
                        <div className={'title-container__title'}>
                            <h2>{markedText(this.props.heading)}</h2>
                            <div className={'title-container__text'}>
                                {this.props.sub ? markedText(this.props.sub) : ''}
                                <div style={{fontStyle: 'italic'}}>(Meerdere antwoorden mogelijk)</div>
                            </div>
                        </div>
                    </div>

                    <div className={'body-container'}>
                        {
                            this.props.options.length <= 4 ?
                                <div className={'options-container'}>
                                    {options}
                                </div> :
                                options
                        }
                    </div>

                    {
                        this.state.displayFeedback &&
                        <React.Fragment>
                            <div className={'feedback-overlay'}></div>

                            <div className={'feedback-modal-container'}>
                                <div className={'feedback-modal'}>
                                    {markedText(this.props.feedback)}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>

                <NavBar
                    isNextDisabled={this.state.answersIndexArray.length < 1}
                    handleNextClick={() => this.goToNextState()}
                    handlePreviousClick={() => this.goToPreviousState()}
                />
            </div>
        );
    }

    private giveAnswer(answerIndex: number) {
        if (this.isAnswerIndexGiven(answerIndex)) {
            let answersIndexArray = [...this.state.answersIndexArray]; // make a separate copy of the array
            let index = answersIndexArray.indexOf(answerIndex);

            if (index !== -1) {
                answersIndexArray.splice(index, 1);
                this.setState({ answersIndexArray: answersIndexArray });
            }
        } else {
            this.setState(state => ({
                answersIndexArray: [...state.answersIndexArray, answerIndex]
            }));
        }
    }

    private goToNextState() {
        if (!this.state.displayCorrectAnswers && !this.state.displayFeedback) {
            this.props.storeChosenOptions(this.state.answersIndexArray);
            this.setState({
                displayCorrectAnswers: true
            });
        } else if (this.state.displayCorrectAnswers && !this.state.displayFeedback) {
            this.setState({ displayFeedback: true })
        } else if (this.state.displayCorrectAnswers && this.state.displayFeedback) {
            this.props.goToNextItem()
        }
    }

    private goToPreviousState() {
        if (this.state.answersIndexArray === undefined) {
            this.props.goToPreviousItem();
        } else {
            if (this.state.displayFeedback) {
                this.setState({ displayFeedback: false })
            } else {
                this.props.goToPreviousItem();
            }
        }
    }

    private isAnswerIndexGiven(index: number): boolean {
        return this.state.answersIndexArray.indexOf(index) !== -1;
    }
}

export default MultipleSelect;
