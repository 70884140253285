export function getLessonIcon(lessonNumber: number) {
    switch (lessonNumber) {
        case 1:
            return require('../resources/icons/heart_line.svg');
        case 2:
            return require('../resources/icons/condom_line.svg');
        case 3:
            return require('../resources/icons/spiral_line.svg');
        case 4:
            return require('../resources/icons/pacifier_line.svg');
        case 5:
            return require('../resources/icons/gender_line.svg');
        default:
            return require('../resources/icons/condom_line.svg');
    }
}

export function getLessonIconFilled(lessonNumber: number) {
    switch (lessonNumber) {
        case 1:
            return require('../resources/icons/heart_filled.svg');
        case 2:
            return require('../resources/icons/condom_filled.svg');
        case 3:
            return require('../resources/icons/spiral_filled.svg');
        case 4:
            return require('../resources/icons/pacifier_filled.svg');
        case 5:
            return require('../resources/icons/gender_filled.svg');
        default:
            return require('../resources/icons/condom_filled.svg');
    }
}
