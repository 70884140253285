import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";
import { getQuoteeImage } from "../../util/imageHelper";

export interface Props {
    bg: string,
    heading: string,
    sub: string,
    items: any,
    goToNextItem: any,
    goToPreviousItem: any,
}

interface State {
}

class Quotes extends Component<Props, State> {
    render() {
        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'title-container'}>
                        <div className={'title-container__title'}>
                            <h2>{markedText(this.props.heading)}</h2>
                            {
                                this.props.sub &&
                                <div className={'title-container__text'}>
                                    {markedText(this.props.sub)}
                                </div>
                            }
                        </div>
                    </div>

                    <div className={'body-container'}>

                        {
                            this.props.items[0] && this.props.items[0].quote && this.props.items[0].quote.length > 0 &&
                            <div className={'quote quote--0'}>
                                <img src={getQuoteeImage(this.props.items[0].image)} alt={''}/>
                                <div className={'quote__text'}>
                                    "{this.props.items[0].quote}"
                                </div>
                                <div className={'quote__quotee'}>
                                    {this.props.items[0].quotee}
                                </div>
                            </div>
                        }

                        {
                            this.props.items[1] &&
                            <div className={'quote quote--1'}>
                                <img src={getQuoteeImage(this.props.items[1].image)} alt={''}/>
                                <div className={'quote__text'}>
                                    "{this.props.items[1].quote}"
                                </div>
                                <div className={'quote__quotee'}>
                                    {this.props.items[1].quotee}
                                </div>
                            </div>
                        }

                        {
                            this.props.items[2] &&
                            <div className={'quote quote--2'}>
                                <img src={getQuoteeImage(this.props.items[2].image)} alt={''}/>
                                <div className={'quote__text'}>
                                    "{this.props.items[2].quote}"
                                </div>
                                <div className={'quote__quotee'}>
                                    {this.props.items[2].quotee}
                                </div>
                            </div>
                        }

                    </div>
                </div>

                <NavBar handleNextClick={() => this.props.goToNextItem()}
                        handlePreviousClick={() => this.props.goToPreviousItem()}
                />
            </div>
        );
    }
}

export default Quotes;
