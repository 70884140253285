import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";
import Player from '@vimeo/player';
import Raven = require("raven-js");

export interface Props {
    bg: string,
    heading: string,
    videoId?: number,
    goToNextItem: any,
    goToPreviousItem: any,
    storeIsCompleted: any,
    isCompleted: boolean,
}

interface State {
    isPlaying: boolean,
    isNextDisabled: boolean,
}

class Video extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isPlaying: false,
            isNextDisabled: !this.props.isCompleted,
        }
    };

    componentDidMount() {
        try {
            if (Number.isInteger(this.props.videoId)) {
                this.createPlayer();
            } else {
                this.setState({
                    isPlaying: false,
                    isNextDisabled: false,
                });
                console.error('videoId is not an integer: ' + this.props.videoId);
                throw new Error('videoId is not an integer: ' + this.props.videoId);
            }
        } catch (e) {
            Raven.captureException(e)
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.videoId !== this.props.videoId) {
            this.setState({
                isPlaying: false,
                isNextDisabled: !this.props.isCompleted,
            })
        }
    }

    render() {
        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    <div className={'title-container'}>
                        <div className={'title-container__title'}>
                            <h2>{markedText(this.props.heading)}</h2>
                        </div>
                    </div>

                    <div id="vimeo-container" style={{ zIndex: this.state.isPlaying ? 10 : 0 }}></div>

                </div>

                <NavBar handleNextClick={() => this.handleNextClick()}
                        handlePreviousClick={() => this.props.goToPreviousItem()}
                        isNextDisabled={this.state.isNextDisabled}
                />
            </div>
        );
    }

    private handleNextClick() {
        this.props.storeIsCompleted(true);
        this.props.goToNextItem()
    }

    private createPlayer() {
        const player = new Player('vimeo-container', {
            id: this.props.videoId,
            width: 950,
            height: 670,
            title: false,
            byline: false,
            transparent: false,
        });

        player.on('play', () => {
            this.setState({
                isPlaying: true,
                isNextDisabled: false,
            });
        });

        player.on('ended', () => {
            this.setState({
                isPlaying: false,
                isNextDisabled: false,
            });
        });

        player.on('pause', () => {
            this.setState({
                isPlaying: false,
                isNextDisabled: false,
            });
        });

        player.on('error', () => {
            this.setState({
                isPlaying: false,
                isNextDisabled: false,
            });
        });
    }
}

export default Video;
