import Raven = require("raven-js");

export function getBackgroundColor(itemNumber: number, backgroundImageName: string) {
    const bgColors = [
        '#870a60', //Paars
        '#4b86ae', //Blauw
        '#85ab85', //Groen
        '#ede48b', //Geel
        '#cf6d38', //Oranje
    ];

    if (backgroundImageName !== undefined) {
        switch (getNameWithPngExtension(backgroundImageName)) {
            case 'NB_Patroon_blauwpaars.png':
                return '#4b86ae';
            case 'NB_Patroon_geel.png':
                return '#ede48b';
            case 'NB_Patroon_geelgroen.png':
                return '#85ab85';
            case 'NB_Patroon_groen.png':
                return '#85ab85';
            case 'NB_Patroon_oranje.png':
                return '#cf6d38';
        }
    }

    return bgColors[itemNumber % bgColors.length];
}

export function getHomePattern() {
    return require('../resources/patterns/pattern_11.png');
}

export function getBackgroundImage(name: string) {
    const bgImages: any = {
        'B_Blauwe-lucht.png': require('../resources/images/backgrounds/B_Blauwe-lucht.png'),
        'B_Condoom-in-bed.png': require('../resources/images/backgrounds/B_Condoom-in-bed.png'),
        'B_Condoom-pil.png': require('../resources/images/backgrounds/B_Condoom-pil.png'),
        'B_Condooms-veel.png': require('../resources/images/backgrounds/B_Condooms-veel.png'),
        'B_Condooms.png': require('../resources/images/backgrounds/B_Condooms.png'),
        'B_Douchen-voeten.png': require('../resources/images/backgrounds/B_Douchen-voeten.png'),
        'B_Gras-bloemen-lucht.png': require('../resources/images/backgrounds/B_Gras-bloemen-lucht.png'),
        'B_Gras-lucht.png': require('../resources/images/backgrounds/B_Gras-lucht.png'),
        'B_Groep-jongeren-buiten.png': require('../resources/images/backgrounds/B_Groep-jongeren-buiten.png'),
        'B_Groep-jongeren-tafel.png': require('../resources/images/backgrounds/B_Groep-jongeren-tafel.png'),
        'B_Hartje-handen-lucht.png': require('../resources/images/backgrounds/B_Hartje-handen-lucht.png'),
        'B_Hormoonspiraal-anticonceptie.png': require('../resources/images/backgrounds/B_Hormoonspiraal-anticonceptie.png'),
        'B_Jongen-blij.png': require('../resources/images/backgrounds/B_Jongen-blij.png'),
        'B_Jongen-buiten-bank.png': require('../resources/images/backgrounds/B_Jongen-buiten-bank.png'),
        'B_Jongen-buiten-lach.png': require('../resources/images/backgrounds/B_Jongen-buiten-lach.png'),
        'B_Jongen-buiten.png': require('../resources/images/backgrounds/B_Jongen-buiten.png'),
        'B_Jongen-condoom-zak.png': require('../resources/images/backgrounds/B_Jongen-condoom-zak.png'),
        'B_Jongen-condoom.png': require('../resources/images/backgrounds/B_Jongen-condoom.png'),
        'B_Jongen-meisje-aula.png': require('../resources/images/backgrounds/B_Jongen-meisje-aula.png'),
        'B_Jongen-meisje-buiten.png': require('../resources/images/backgrounds/B_Jongen-meisje-buiten.png'),
        'B_Jongen-meisje-date.png': require('../resources/images/backgrounds/B_Jongen-meisje-date.png'),
        'B_Jongen-meisje-praten.png': require('../resources/images/backgrounds/B_Jongen-meisje-praten.png'),
        'B_Jongen-meisje-samenwerken.png': require('../resources/images/backgrounds/B_Jongen-meisje-samenwerken.png'),
        'B_Jongen-steun-jongen.png': require('../resources/images/backgrounds/B_Jongen-steun-jongen.png'),
        'B_Jongen-telefoon.png': require('../resources/images/backgrounds/B_Jongen-telefoon.png'),
        'B_Jongens-huilen.png': require('../resources/images/backgrounds/B_Jongens-huilen.png'),
        'B_Jongens-praten.png': require('../resources/images/backgrounds/B_Jongens-praten.png'),
        'B_Jongeren-perronbank.png': require('../resources/images/backgrounds/B_Jongeren-perronbank.png'),
        'B_Jongeren-trap.png': require('../resources/images/backgrounds/B_Jongeren-trap.png'),
        'B_Jongeren-trein.png': require('../resources/images/backgrounds/B_Jongeren-trein.png'),
        'B_Jongeren-werkend.png': require('../resources/images/backgrounds/B_Jongeren-werkend.png'),
        'B_Koperspiraal-anticonceptie.png': require('../resources/images/backgrounds/B_Koperspiraal-anticonceptie.png'),
        'B_Koppel-omhelzen.png': require('../resources/images/backgrounds/B_Koppel-omhelzen.png'),
        'B_Koppel.png': require('../resources/images/backgrounds/B_Koppel.png'),
        'B_Meiden-sip-gesprek.png': require('../resources/images/backgrounds/B_Meiden-sip-gesprek.png'),
        'B_Meisje-buiten-blond.png': require('../resources/images/backgrounds/B_Meisje-buiten-blond.png'),
        'B_Meisje-buiten-bruin.png': require('../resources/images/backgrounds/B_Meisje-buiten-bruin.png'),
        'B_Meisje-buiten-staan.png': require('../resources/images/backgrounds/B_Meisje-buiten-staan.png'),
        'B_Meisje-buiten.png': require('../resources/images/backgrounds/B_Meisje-buiten.png'),
        'B_Meisje-condoom.png': require('../resources/images/backgrounds/B_Meisje-condoom.png'),
        'B_Meisje-lach-binnen.png': require('../resources/images/backgrounds/B_Meisje-lach-binnen.png'),
        'B_Meisje-treurig-mobiel.png': require('../resources/images/backgrounds/B_Meisje-treurig-mobiel.png'),
        'B_Meisje-treurig.png': require('../resources/images/backgrounds/B_Meisje-treurig.png'),
        'B_Meisje-zwangerschapstest-afro.png': require('../resources/images/backgrounds/B_Meisje-zwangerschapstest-afro.png'),
        'B_Meisje-zwangerschapstest.png': require('../resources/images/backgrounds/B_Meisje-zwangerschapstest.png'),
        'B_Meisjes-kletsen.png': require('../resources/images/backgrounds/B_Meisjes-kletsen.png'),
        'B_Meisjes-lachen.png': require('../resources/images/backgrounds/B_Meisjes-lachen.png'),
        'B_Meisjes-lachend.png': require('../resources/images/backgrounds/B_Meisjes-lachend.png'),
        'B_Meisjes-steun-rood.png': require('../resources/images/backgrounds/B_Meisjes-steun-rood.png'),
        'B_Meisjes-steun.png': require('../resources/images/backgrounds/B_Meisjes-steun.png'),
        'B_Morningafterpil.png': require('../resources/images/backgrounds/B_Morningafterpil.png'),
        'B_Pil-anticonceptie.png': require('../resources/images/backgrounds/B_Pil-anticonceptie.png'),
        'B_Pil-jongen.png': require('../resources/images/backgrounds/B_Pil-jongen.png'),
        'B_Pil-strip.png': require('../resources/images/backgrounds/B_Pil-strip.png'),
        'B_Pleister-anticonceptie.png': require('../resources/images/backgrounds/B_Pleister-anticonceptie.png'),
        'B_Prikpil-anticonceptie.png': require('../resources/images/backgrounds/B_Prikpil-anticonceptie.png'),
        'B_Regenboog-button.png': require('../resources/images/backgrounds/B_Regenboog-button.png'),
        'B_Ring-anticonceptie.png': require('../resources/images/backgrounds/B_Ring-anticonceptie.png'),
        'B_Staafje-anticonceptie.png': require('../resources/images/backgrounds/B_Staafje-anticonceptie.png'),
        'B_Zwanger-meisje.png': require('../resources/images/backgrounds/B_Zwanger-meisje.png'),
        'B_Zwangerschapstest.png': require('../resources/images/backgrounds/B_Zwangerschapstest.png'),
        'NB_Blauwe-lucht.png': require('../resources/images/backgrounds/NB_Blauwe-lucht.png'),
        'NB_Condooms.png': require('../resources/images/backgrounds/NB_Condooms.png'),
        'NB_Effen_Geel.png': require('../resources/images/backgrounds/NB_Effen_Geel.png'),
        'NB_Gras-bloemen-lucht.png': require('../resources/images/backgrounds/NB_Gras-bloemen-lucht.png'),
        'NB_Gras-lucht.png': require('../resources/images/backgrounds/NB_Gras-lucht.png'),
        'NB_Hartje-handen-lucht.png': require('../resources/images/backgrounds/NB_Hartje-handen-lucht.png'),
        'NB_Hormoonspiraal-anticonceptie.png': require('../resources/images/backgrounds/NB_Hormoonspiraal-anticonceptie.png'),
        'NB_Jongen-condoom-zak.png': require('../resources/images/backgrounds/NB_Jongen-condoom-zak.png'),
        'NB_Jongen-condoom.png': require('../resources/images/backgrounds/NB_Jongen-condoom.png'),
        'NB_Koperspiraal-anticonceptie.png': require('../resources/images/backgrounds/NB_Koperspiraal-anticonceptie.png'),
        'NB_Meisje-condoom.png': require('../resources/images/backgrounds/NB_Meisje-condoom.png'),
        'NB_Meisjes-steun-rood.png': require('../resources/images/backgrounds/NB_Meisjes-steun-rood.png'),
        'NB_Meisjes-steun.png': require('../resources/images/backgrounds/NB_Meisjes-steun.png'),
        'NB_Menstruatiemiddelen.png': require('../resources/images/backgrounds/NB_Menstruatiemiddelen.png'),
        'NB_Morningafterpil.png': require('../resources/images/backgrounds/NB_Morningafterpil.png'),
        'NB_Patroon_blauwpaars.png': require('../resources/images/backgrounds/NB_Patroon_blauwpaars.png'),
        'NB_Patroon_geel.png': require('../resources/images/backgrounds/NB_Patroon_geel.png'),
        'NB_Patroon_geelgroen.png': require('../resources/images/backgrounds/NB_Patroon_geelgroen.png'),
        'NB_Patroon_groen.png': require('../resources/images/backgrounds/NB_Patroon_groen.png'),
        'NB_Patroon_oranje.png': require('../resources/images/backgrounds/NB_Patroon_oranje.png'),
        'NB_Penis1.png': require('../resources/images/backgrounds/NB_Penis1.png'),
        'NB_Penis2.png': require('../resources/images/backgrounds/NB_Penis2.png'),
        'NB_Pil-anticonceptie.png': require('../resources/images/backgrounds/NB_Pil-anticonceptie.png'),
        'NB_Pil-strip.png': require('../resources/images/backgrounds/NB_Pil-strip.png'),
        'NB_Pil.png': require('../resources/images/backgrounds/NB_Pil.png'),
        'NB_Pleister-anticonceptie.png': require('../resources/images/backgrounds/NB_Pleister-anticonceptie.png'),
        'NB_Prikpil-anticonceptie.png': require('../resources/images/backgrounds/NB_Prikpil-anticonceptie.png'),
        'NB_Ring-anticonceptie.png': require('../resources/images/backgrounds/NB_Ring-anticonceptie.png'),
        'NB_Staafje-anticonceptie.png': require('../resources/images/backgrounds/NB_Staafje-anticonceptie.png'),
        'NB_Tijdslijn_Les2.png': require('../resources/images/backgrounds/NB_Tijdslijn_Les2.png'),
        'NB_Vulva1.png': require('../resources/images/backgrounds/NB_Vulva1.png'),
        'NB_Vulva2.png': require('../resources/images/backgrounds/NB_Vulva2.png'),
        'NB_Zora.png': require('../resources/images/backgrounds/NB_Zora.png'),
        'NB_Zwanger-meisje.png': require('../resources/images/backgrounds/NB_Zwanger-meisje.png'),
        'NB_Zwangerschapstest.png': require('../resources/images/backgrounds/NB_Zwangerschapstest.png'),
        'zwanger_wat_nu.png': require('../resources/images/backgrounds/zwanger_wat_nu.png'),
        'NB_Penis-bes-onsbes.png': require('../resources/images/backgrounds/NB_Penis-bes-onsbes.png'),
        'NB_Jongen-lldl-condoom.png': require('../resources/images/backgrounds/NB_Jongen-lldl-condoom.png'),
        'NB_Meisje-condoom-scheuren.png': require('../resources/images/backgrounds/NB_Meisje-condoom-scheuren.png'),
        'B_Clitoris.png': require('../resources/images/backgrounds/B_Clitoris.jpg'),
        'B_Ja-en-header.png': require('../resources/images/backgrounds/B_Ja-en-header.png'),
        'B_Meisje-straat.png': require('../resources/images/backgrounds/B_Meisje-straat.jpg'),
        'B_social-media.png': require('../resources/images/backgrounds/B_social-media.jpg'),
        'vraagtekens.png': require('../resources/images/backgrounds/vraagtekens.jpg'),
        'B_verdriet.png': require('../resources/images/backgrounds/B_verdriet.jpg'),
        'can you fix it.png': require('../resources/images/backgrounds/can you fix it.png'),
    };

    try {
        name = getNameWithPngExtension(name);
        if (bgImages[name] === undefined) {
            console.error('background not found:', name);
            throw new Error('background not found: ' + name);
        }
    } catch (e) {
        Raven.captureException(e)
    }

    return bgImages[name];
}

export function getQuoteeImage(name: string) {
    const quoteeImages: any = {
        'quote_devin.png': require('../resources/images/quotees/quote_devin.png'),
        'quote_amber.png': require('../resources/images/quotees/quote_amber.png'),
        'quote_freek.png': require('../resources/images/quotees/quote_freek.png'),
        'quote_jessy.png': require('../resources/images/quotees/quote_jessy.png'),
        'quote_jur.png': require('../resources/images/quotees/quote_jur.png'),
        'quote_pim.png': require('../resources/images/quotees/quote_pim.png'),
        'quote_rico.png': require('../resources/images/quotees/quote_rico.png'),
        'quote_sylvia.png': require('../resources/images/quotees/quote_sylvia.png'),
        'quote_amina.png': require('../resources/images/quotees/quote_amina.png'),
        'quote_farah.png': require('../resources/images/quotees/quote_farah.png'),
        'quote_jelou.png': require('../resources/images/quotees/quote_jelou.png'),
        'quote_jordy.png': require('../resources/images/quotees/quote_jordy.png'),
        'quote_lois.png': require('../resources/images/quotees/quote_lois.png'),
        'quote_quincy.png': require('../resources/images/quotees/quote_quincy.png'),
        'quote_sjors.png': require('../resources/images/quotees/quote_sjors.png'),
        'quote_zora.png': require('../resources/images/quotees/quote_zora.png'),
        'quote_fay.png': require('../resources/images/quotees/quote_fay.png'),
        'quote_dylan.png': require('../resources/images/quotees/quote_dylan.png'),
    };

    try {
        name = getNameWithPngExtension(name);
        if (quoteeImages[name] === undefined) {
            console.error('quotee image not found:', name);
            throw new Error('quotee image not found: ' + name);
        }
    } catch (e) {
        Raven.captureException(e)
    }

    return quoteeImages[name];
}

function getNameWithPngExtension(name: string) {
    if (name.indexOf('.') === -1) {
        return name + '.png';
    } else {
        return name.substr(0, name.indexOf('.')) + '.png';
    }
}
