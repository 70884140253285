import * as React from "react";
import { Component } from "react";
import { getLessonIcon, getLessonIconFilled } from "../util/lessonIcon";
import {default as classNames} from "classnames";

export interface Props {
    chapterIndex: number,
    lessonIndex: number,
    itemNumber: number,
    goToHomePage: any,
    lessons: any,
}

interface State {
}

class NavBar extends Component<Props, State> {
    render() {
        return (
            <div className={'top'}>
                <div className={'top__lesson'}>
                    les {this.props.lessonIndex}
                </div>

                <div className={'header header_lesson_' + this.props.lessonIndex}>
                    <img src={require('../../src/resources/images/logo.svg')}
                         className={'header__logo'}
                         alt={'lang leve de liefde logo'}
                         onClick={() => this.props.goToHomePage()} />
                    <div className={'header__state-container'}>
                        <div className={'header__progress'}>{this.renderProgressIcons()}</div>
                        <div className={'header__subject'}>{this.getCurrentChapter().title}</div>
                    </div>
                </div>
            </div>
        );
    }

    private renderProgressIcons() {
        let progressChapters = [];
        let itemCounter = 0;
        for (let chapterIndex = 0; chapterIndex < this.props.lessons[this.props.lessonIndex].chapters.length; chapterIndex++) {
            let progressIcons = [];

            for (let itemIndex = 0; itemIndex < this.props.lessons[this.props.lessonIndex].chapters[chapterIndex].items.length; itemIndex++) {
                if (this.props.lessons[this.props.lessonIndex].chapters[chapterIndex].items[itemIndex].type === 'eind_scherm') {
                    // Don't add a progress icon for the eind_scherm.
                } else {
                    if (this.props.itemNumber > itemCounter) {
                        progressIcons.push(<img key={itemIndex}
                                                src={getLessonIconFilled(this.props.lessonIndex)}
                                                alt={''} />)
                    } else {
                        progressIcons.push(<img key={itemIndex}
                                                src={getLessonIcon(this.props.lessonIndex)}
                                                alt={''} />)
                    }
                    itemCounter++;
                }
            }

            progressChapters.push(
                <div key={chapterIndex} className={classNames({
                    'chapter-progress': true,
                    'chapter-progress--passed': chapterIndex < this.props.chapterIndex,
                    'chapter-progress--active': chapterIndex === this.props.chapterIndex,
                })}>
                    {progressIcons}
                </div>
            )
        }

        return progressChapters;
    }

    private getCurrentChapter() {
        return this.props.lessons[this.props.lessonIndex].chapters[this.props.chapterIndex];
    }
}

export default NavBar;
