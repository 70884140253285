import * as React from "react";
import * as ReactDOM from "react-dom";

import 'babel-polyfill'
import '../style/main.scss';
import '../style/home.scss';
import '../style/top.scss';
import '../style/navbar.scss';
import '../style/text.scss';
import '../style/items.scss';
import '../style/quotes.scss';
import '../style/password.scss';
import Main from "./components/Main";


if (process.env.NODE_ENV !== 'production') {
    console.log('FYI: we are in development mode!');
} else {
    window.onbeforeunload = () => true
}

let lessons = require('./resources/content.json');

ReactDOM.render(
    <Main lessons={lessons}/>,
    document.getElementById("root")
);
