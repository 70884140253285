import * as React from "react";
import { Component } from "react";
import { markedText } from "../../util/markedText";
import NavBar from "../NavBar";
import ReactSVG from 'react-svg'


//
// hotspot platen exporteren HOWTO
//
// - open een illustrator document
// - lock alle lagen behalve 'afbeelding'
// - select all, kies Object > Rasterize, met CYMK (weet niet waarom), en kies transparant als achtergrond en niet wit, verder defaults
// - lock alle lagen behalve de infolagen met tekst ('week...' bij menstruatiecyclus)
// - select all, kies Type > Create Outlines (daarmee maak de tekst 'vast' en voorkom je problemen bij mensen die de fonts niet hebben)
// - lock alleen de overlay
// - select all en kies File > Export Selection...
// - kies svg als export
// - kies de juiste naam voor het te exporteren bestand (dus niet 'Asset 1' of iets dergelijks, maar check in de sources wat nu gebruikt wordt)
// - laat de rest van de defaults staan
// - export!
// - kopieer svg en bijbehorende png files naar frontent/src/resources/hotspot
// - test, en klaar!
//


export interface Props {
    bg: string,
    type: string,
    heading: string,
    sub?: string,
    select: string,
    goToNextItem: any,
    goToPreviousItem: any,
}

interface State {
    nextDisabled: boolean,
    selectedItem: string,
    itemsToClick: string[],
}

interface HotspotConfig {
    items: string[],
    svg: string,
    overview?: boolean,
    overviewInteractive?: boolean,
    imgSrcPrefix: string,
    height?: string,
    width?: string,
    top?: number,
    left?: number,
}

const settings:{ [key:string]:HotspotConfig; } = {
    "vrouw buitenkant": { // is eigenlijkt 'binnenkant'
        items: [
            "baarmoedermond",
            "eierstok",
            "schaamlippen",
            "vagina",
            "baarmoeder",
            "eileider",
        ],
        svg: "resources/hotspot/vrouw_binnen/vrouw_binnen.svg",
        imgSrcPrefix: "resources/hotspot/vrouw_binnen/",
    },
    "man buitenkant": {
        items: [
            "balzak",
            "plasgaatje",
            "eikel",
            "voorhuid",
            "schacht",
        ],
        svg: "resources/hotspot/man_buiten/man_buiten.svg",
        imgSrcPrefix: "resources/hotspot/man_buiten/",
    },
    "man binnenkant": {
        items: [
            "zaadleider",
            "zaadbal",
            "balzak",
            "plasgaatje",
            "eikel",
            "plasbuis",
            "zwellichaam",
        ],
        svg: "resources/hotspot/man_binnen/man_binnen.svg",
        imgSrcPrefix: "resources/hotspot/man_binnen/",
    },
    "vulva": {
        items: [
            "plasgaatje",
            "anus",
            "vagina",
            "schaamlippen",
            "clitoris",
        ],
        svg: "resources/hotspot/vulva_buiten/vulva_buiten.svg",
        imgSrcPrefix: "resources/hotspot/vulva_buiten/",
        height: '480px',
        top: 60
    },
    "menstruatiecyclus": {
        items: [
            "week_1", // + _tekst
            "week_2",
            "week_3",
            "week_4",
        ],
        svg: "resources/hotspot/menstruatiecyclus/menstruatiecyclus.svg",
        imgSrcPrefix: "resources/hotspot/menstruatiecyclus/",
        overviewInteractive: true,
        height: '435px',
        width: 'auto',
        top: 80,
        left: -25
    },
    "zwangerschap": {
        items: [
            "week_1", // + _tekst
            "week_2",
            "week_3",
        ],
        svg: "resources/hotspot/zwangershap/zwangerschap.svg",
        imgSrcPrefix: "resources/hotspot/zwangershap/",
        overviewInteractive: true,
        top: 40
    },
};


// add aliases for 'overzicht' versions
[
    "vrouw buitenkant",
    "man buitenkant",
    "man binnenkant",
    "vulva"
].forEach((name) => {
    settings['overzicht ' + name] = Object.assign({}, settings[name], {'overview': true});
})

class Hotspot extends Component<Props, State> {

    private config:HotspotConfig;

    constructor(props: Props) {
        super(props);
        let config:HotspotConfig;

        // duplicate code with init()
        if (settings[this.props.type]) {
            config = settings[this.props.type];
        }

        let nextDisabled = config.overview ? false : true;
        let itemsToClick = config.overviewInteractive ? config.items : [];
        this.state = {
            nextDisabled: nextDisabled,
            selectedItem: null,
            itemsToClick
        }
    };
    componentDidUpdate(prevProps:Props) {
        if (
            this.props.type !== prevProps.type
            || this.props.select !== prevProps.select
            || this.props.heading !== prevProps.heading
        ) {
            this.init();
        }
    }
    private init() {
        let config:HotspotConfig;

        // duplicate code with constructor
        if (settings[this.props.type]) {
            config = settings[this.props.type];
        }

        let nextDisabled = config.overview ? false : true;
        let itemsToClick = config.overviewInteractive ? config.items : [];
        this.setState( {
            nextDisabled: nextDisabled,
            selectedItem: null,
            itemsToClick
        });
    }
    render() {

        return (
            <div className={'item'}>
                <div className={'item__content-container'}>

                    {this.props.heading ?
                        <div className={'title-container'}>
                            <div className={'title-container__title'} style={{margin: '17px auto 0 auto'}}>
                                <h2>{markedText(this.props.heading)}</h2>
                                {
                                    this.props.sub &&
                                    <div className={'title-container__text'}>
                                        {markedText(this.props.sub)}
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        null
                    }

                    {this.renderHotspot()}

                </div>

                <NavBar handleNextClick={() => this.props.goToNextItem()}
                        handlePreviousClick={() => this.props.goToPreviousItem()}
                        isNextDisabled={this.state.nextDisabled}
                />
            </div>
        );
    }
    selectItem (itemId:string):void {


        let selectedItem = this.state.selectedItem;
        let itemsToClick = this.state.itemsToClick;

        if (selectedItem == itemId || itemId === null) {
            // unselect!
            selectedItem = null;
        } else {
            selectedItem = itemId;
            // store as 'clicked' by removing it from the 'to click' array
            itemsToClick = itemsToClick.filter((itemInList) => itemInList !== itemId);
        }
        console.log(selectedItem);

        let nextDisabled;

        if ((this.props.select && this.props.select === selectedItem) || this.config.overview) {
            nextDisabled = false;
        } else if (this.config.overviewInteractive) {
            // disabled if there are still items to click
            nextDisabled = itemsToClick.length > 0;
        } else {
            nextDisabled = true;
        }

        console.log('selectItem', 'nextDisabled', nextDisabled);
        this.setState({selectedItem: selectedItem, nextDisabled, itemsToClick});
    }

    renderHotspot() {

        //
        // always set this here -> you'll get react errors if you set this in the contructor and componentDidUpdate
        //
        if (settings[this.props.type]) {
            this.config = settings[this.props.type];
        } else {
            this.config = null;
        }

        // get config for this specific hotspot
        if (! this.config) {
            // guard
            return <div>Fout: configuratie niet gevonden</div>
        }

        // another guard
        if (!this.config.svg) {
            return <div><h1>Helaas, deze klikplaat is nog niet gemaakt</h1></div>
        }

        let imageHeight = this.config.height ? this.config.height : '540px';
        let imageWidth = this.config.width ? this.config.width : '100%';
        let imageTop = this.config.top ? this.config.top : 0;
        let imageLeft = this.config.left ? this.config.left : 0;

        return <div className='hotspot-container'>
            <ReactSVG
                svgStyle={{position: 'absolute', top: imageTop, left: imageLeft, width: `${imageWidth}`, height: `${imageHeight}`}}
                className="hotspot-svg-embed"
                src={this.config.svg}
                onInjected={(err, domElement) => {

                    if (err) {
                        console.error(err);
                        return;
                    }

                    // HACK to fix images
                    let images = domElement.getElementsByTagName('image');
                    let prefix = this.config.imgSrcPrefix;
                    for (var i = 0; i < images.length; i++) {
                        if (false) {
                            // for debugging
                            console.log(images[i]);
                            console.log(images[i].getAttributeNS('xlink', 'href'));
                            console.log(images[i].getAttribute('xlink:href'));
                        }

                        images[i].setAttribute('xlink:href', prefix + images[i].getAttribute('xlink:href'))
                    }

                    let items:string[] = settings[this.props.type].items;

                    const selectItem = (item:string) => this.selectItem(item);
                    const deselect = () => this.selectItem(null);

                    if (this.config.overview) {
                        // show only 'white' labels
                        items.forEach((val:string) => {
                            let on = domElement.getElementById(val + "_on");
                            let off = domElement.getElementById(val + "_off");
                            let incorrect = domElement.getElementById(val + "_fout");
                            let correct = domElement.getElementById(val + "_goed");
                            if (on) {
                                on.setAttributeNS(null, 'visibility', 'visible');
                            }
                            if (incorrect) {
                                incorrect.setAttributeNS(null, 'visibility', 'hidden');
                            }
                            if (correct) {
                                correct.setAttributeNS(null, 'visibility', 'hidden');
                            }
                        });
                    } else {
                        if (this.config.overviewInteractive) { // for 'menstruatiecyclus' and 'zwangerschap'
                            domElement.addEventListener('click',  () => {
                                if (this.state.selectedItem) {
                                    deselect();
                                }
                            });
                            items.forEach((val:string) => {
                                let off = domElement.getElementById(val);
                                let on = domElement.getElementById(val + "_tekst");
                                if (this.state.selectedItem == val) {
                                    on.setAttributeNS(null, 'visibility', 'visible');
                                } else {
                                    on.setAttributeNS(null, 'visibility', 'hidden');
                                }
                                off.addEventListener('click',  (evt) => {
                                    selectItem(val);
                                    evt.stopPropagation();
                                });
                                on.addEventListener('click',  (evt) => {
                                    selectItem(val);
                                    evt.stopPropagation();
                                });
                            });
                        } else {
                            items.forEach((val:string) => {
                                console.log('handling item', val);
                                let on = domElement.getElementById(val + "_on");
                                let off = domElement.getElementById(val + "_off");
                                let incorrect = domElement.getElementById(val + "_fout");
                                let correct = domElement.getElementById(val + "_goed");
                                if (this.state.selectedItem == val) {
                                    on.setAttributeNS(null, 'visibility', 'visible');
                                    let currentAnswerCorrect = this.props.select === this.state.selectedItem;
                                    incorrect.setAttributeNS(null, 'visibility', currentAnswerCorrect ? 'hidden' : 'visible');
                                    correct.setAttributeNS(null, 'visibility', currentAnswerCorrect ? 'visible' : 'hidden');
                                } else {
                                    // null checks - otherwise safari crashes on leaving the page
                                    if (on) {
                                        on.setAttributeNS(null, 'visibility', 'hidden');
                                    }
                                    if (incorrect) {
                                        incorrect.setAttributeNS(null, 'visibility', 'hidden');
                                    }
                                    if (correct) {
                                        correct.setAttributeNS(null, 'visibility', 'hidden');
                                    }
                                }
                                // null check - otherwise safari crashes on leaving the page
                                if (off) {
                                    off.addEventListener('click', function () {
                                        selectItem(val);
                                    })
                                }
                            });
                        }
                    }
                }}
                onClick={(e) => {
                    // console.log('Hotspot onClick', e)
                }}
            />
        </div>;
    }
}

export default Hotspot;
