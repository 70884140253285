import * as React from "react";

export function markedText(input: string):JSX.Element {
    if (input) {
        //
        // regex is snippet of code from https://dev.to/casualwriter/a-simple-markdown-parser-in-50-lines-of-js-4gpi
        //
        // Any time text appears like this: [Url text](http://site.url.local/something) it will be converted
        // to an external link. This is similar to the way markdown handles links, however the target="_blank"
        // is specific to this project/code.
        //
        input = input ? String(input).replace(/\[(.*?)\]\((.*?)\)/gm, '<a href="$2" target="_blank">$1</a>') : '';

        // now process the **bold** annotation by adding spans.
        return <React.Fragment>
            {
                input.split('**').map(function(part, idx) {
                    if (idx % 2 == 0) {
                        return <span key={idx} dangerouslySetInnerHTML={{ __html: part }}></span>;
                    } else {
                        return <span key={idx} className="highlight" dangerouslySetInnerHTML={{ __html: part }}></span>;
                    }
                })
            }
        </React.Fragment>;
    } else {
        return null;
    }

}
